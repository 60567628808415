@use '@/styles/main' as *;

.googleBtn {
  align-items: center;
  background: $color_white_100;
  background-clip: padding-box;
  border: 1px solid $color_gainsboro_100;
  border-radius: 20px;
  box-shadow: 0 2px 4px 0 $color_black_25;
  display: flex;
  flex-direction: row;
  height: 40px;
  justify-content: center;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.googleIcon {
  object-fit: contain;
}

.googleBtnText {
  @include font_subtitle;

  color: $font_color_b64;
  flex-grow: 0.6;
  text-align: center;
}
