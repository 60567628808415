@use '@/styles/main' as *;

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.input {
  @include font_body;

  color: $font_color_b100;
  align-items: center;
  background: #eef2f3;
  border-radius: 30px;
  display: flex;
  min-height: 40px;
  overflow: hidden;
  padding-left: 30px;
  width: 100%;
  margin-bottom: 12px;
  border: unset;

  &:focus {
    outline-color: transparent;
  }
}

.siginButton {
  height: 40px;
  width: 100%;
}

.forgotPasswordText {
  @include font_body;

  color: $font_color_b64;
  cursor: pointer;
  display: block;
  margin-top: 16px;
  text-align: center;
}
