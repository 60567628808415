@use '@/styles/main' as *;

.container {
  display: flex;
  justify-content: center;
  position: relative;
}

.auraBtn {
  @include font_cta;

  align-items: center;
  background: linear-gradient(277.58deg, #4ec8ff 5.87%, #1df4ed 94.13%);
  border: 0;
  border-radius: 27px;
  color: $font_color_w100;
  cursor: pointer;
  display: flex;
  font-weight: $bold;
  height: 50px;
  justify-content: center;
  line-height: 22px;
  min-width: 180px;
  padding: 0 32px;
  text-align: center;
  white-space: nowrap;
  z-index: 10;

  &:disabled {
    background: none;
    background-color: $color_gray_100;
    cursor: not-allowed;
  }
}

.shadow {
  background: linear-gradient(265.99deg, #03a9f4 4.44%, #01f8ef 100.52%);
  opacity: 0.56;
  filter: blur(25px);
  height: 43px;
  width: 80%;
  position: absolute;
  top: 11px;
}

.reverseGradient {
  background: linear-gradient(270deg, #1df5ed -3.23%, #4ccaff 95.81%);
}
