@use '@/styles/main' as *;

.container {
  width: fit-content;

  .input[type='number']::-webkit-inner-spin-button,
  .input[type='number']::-webkit-outer-spin-button {
    appearance: none;
  }

  @include mediumAndBelow {
    width: 100%;
  }
}

.input {
  @include font_body;
  
  background-color: $color_white_90;
  border: 1px solid rgba(47 50 55 / 15%);
  border-radius: 8px;
  display: block;
  height: 40px;
  line-height: 19px;
  padding: 10px 10px 10px 15px;
  resize: none;
  width: 100%;

  &:focus {
    outline-color: $color_main_100;
  }

  &.error {
    border-color: $color_error_100;
    border-width: 1px;
  }

  &.error:focus {
    outline-color: $color_error_100;
    outline-width: 1px;
  }

  &:disabled {
    background-color: $color_black_20;
  }

  &.textArea {
    height: auto;
  }

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $font_color_dark_grey;
    opacity: 0.5; /* Firefox */
  }
}

.errorMsg {
  @include font_footnote;

  color: $color_error_100;
  display: block;
  height: 16px;

  &::first-letter {
    text-transform: uppercase;
  }
}

.inputHints {
  align-items: baseline;
  display: flex;
  justify-content: space-between;
}
