@use '@/styles/main' as *;

.title {
  @include font_h1;

  color: $font_color_b100;
  margin-bottom: 36px;
  text-align: center;

  @include smallAndBelow {
    @include font_h2;
  }
}

.conatainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 16px 0;
}

.loginCardWrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: center;
  height: 100vh;
}
